:root {
    --light-grey-bg: #FAFAFA;
    --border-color: #E9EAEC;
    --label-color: #111827;
    --light-grey-text: #687588; /*#F2F2F2;*/
    --blue: #274B97;
    --white: white;
    --message-green: #aff29a;
    --text-color: #111827;
    --border-dark: #111827;
    --radius: 16px;
    --manrope: Manrope;
    --poppins: Poppins;
    --red-color: #E03137;
    --menu-link-color: #A0AEC0;
    --black: #000;
    --menu-active-color: #EEEEEE;
    --rounded-pill-bg: #292A3A;
    --toolbar-bg: #F5F5F5;
    --toolbar-button-color: #828282;
    --ligh-border: #F1F2F4;
    --orange: #EA5E23;
    --warning-bg: #FFF5EC;
    --warning-txt: #CC901D;
    --green-color: #27A376;
    --light-blue: #ECF1FF;
}

* {
    font-family: var(--manrope);
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #E6E6E6;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--blue);
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--blue);
    }

body {
    background-color: var(--light-grey-bg);
}

.hidden {
    visibility: hidden;
}

.border, .border-bottom, .border-start {
    border-color: var(--border-color) !important;
}

.border-end {
    border-right: 1px solid var(--border-color);
}

.border-dark {
    border: 1px solid var(--border-dark);
}

.border-red {
    border: 1px solid var(--red-color) !important;
}

.text-red {
    color: var(--red-color) !important;
}

.font-size14{
    font-size: 14px;
}

.br-semi-rounded {
    border-radius: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.main {
    height: 100vh;
    overflow-y: scroll;
}

input[type="checkbox"] {
    width: 1.1em;
    height: 1.1rem;
}

.form-check-input:focus {
    outline: none;
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--red-color);
    border-color: var(--red-color);
}

.available-hours-check:checked {
    background-color: var(--blue);
    border-color: var(--blue);
}

.checkbox-green:checked {
    background-color: var(--green-color);
    border-color: var(--green-color);
}


.sidebar {
    min-height: 100vh;
    height: 100vh;
    transition: 0.3s width;
    background-color: var(--white);
    padding: 40px 25px;
    border-right: 1px solid var(--border-color);
    position: relative;
}

    .sidebar .btn-collapse {
        position: absolute;
        right: -15px;
        border: 0;
        top: calc(100%/2.2);
        background: var(--blue);
        box-shadow: 0px 2px 5px 0px #26334D08;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0;
        height: 50px;
        width: 15px;
    }

        .sidebar .btn-collapse img {
            vertical-align: bottom;
            margin-left: -4px;
        }

    .sidebar .nav-link {
        /*height: 46px;*/
        border-radius: 10px;
        padding: 12px 20px;
    }

    .sidebar .menu-link {
        color: var(--menu-link-color);
        font-weight: 500;
    }

        .sidebar .menu-link:hover {
            border-radius: 10px
        }

        .sidebar .menu-link.active {
            color: var(--black);
            background-color: var(--menu-active-color);
        }


        .sidebar .menu-link img {
            vertical-align: bottom;
        }

    .sidebar .logo-link {
        padding: 0 15px;
    }

.sidebar-collapsed {
}

    .sidebar-collapsed .nav-link {
        padding: 12px;
    }

    .sidebar-collapsed .logo-link {
        padding: 0;
    }

.boxicon-container {
    cursor: pointer;
    padding-top: 5rem;
}

.sidebar-collapsed .boxicon-container {
    padding-top: 5.95rem;
}

.logout {
    position: absolute;
    bottom: 2rem;
}

.first-and-last-trash-fix {
    margin: 0.8rem;
}

.description {
    margin-left: 18px;
}

.count-container {
    margin-top: -2px;
}

.counter {
    padding: 2px 0px;
    border-radius: 79.17px;
    color: var(--white);
    background-color: var(--red-color);
    font-size: 10px;
    display: inline-block;
    height: 19px;
    width: 19px;
    text-align: center;
}

.container {
    display: flex;
    max-width: 100%;
    padding: 0;
}

.page-heading {
    font-size: 24px;
    font-family: var(--manrope);
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.50000000298023224px;
    text-align: left;
    color: var(--text-color);
}

.agency-name {
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    color: var(--text-color);
}

.form-title {
    font-family: var(--manrope);
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.50000000298023224px;
    text-align: left;
    color: var(--text-color);
    margin: 0;
}

.subtitle {
    font-family: var(--manrope);
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: 0.80000000298023224px;
}

    .subtitle img {
        vertical-align: text-bottom;
    }

.form-heading {
}

.form-label, .form-group-label, .form-check-label {
    font-family: var(--manrope);
    color: var(--label-color);
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
}

.form-check-label {
    color: var(--light-grey-text);
}

.form-group-label {
    display: flex;
    align-items: center;
    padding-right: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}

    .form-group-label span {
        color: var(--red-color);
        margin-left: 2px;
    }

.rounded-pill {
    background-color: var(--rounded-pill-bg);
    border-radius: 70px;
    padding: 8px 17px 8px 17px;
    color: var(--white);
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    display: flex;
    align-items: center;
    border: 0;
}

    .rounded-pill:hover {
        background-color: var(--rounded-pill-bg);
        color: var(--white);
    }

    .rounded-pill:first-child:active {
        background-color: var(--rounded-pill-bg) !important;
        color: var(--white) !important;
    }

    .rounded-pill.show {
        background-color: var(--rounded-pill-bg);
        color: var(--white);
    }

.customer-id {
    font-family: var(--manrope);
    font-size: 11px;
    font-weight: 500;
    line-height: 15.03px;
    color: var(--menu-link-color);
    margin-left: 8px;
    margin-right: 16px;
}

.visit-type-pill {
    padding: 6px 22px 6px 22px;
    border-radius: 50px;
    background-color: var(--menu-active-color);
}

.form-container {
    border-radius: 16px;
    background-color: var(--white);
    padding: 20px;
}

.form-control, .form-select {
    height: 52px;
    border: 1px solid var(--border-color);
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    border-radius: 10px !important;
    color: var(--text-color);
}

.multi-select .dropdown-container {
    border: 1px solid var(--border-color);
    border-radius: 10px;
}

.multi-select .dropdown-heading {
    height: 49px;
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    color: var(--text-color);
}

.multi-select .dropdown-content {
    z-index: 200;
    border-radius: 10px;
}

.site-btn {
    background: var(--blue);
    border-radius: 10px;
    color: var(--white);
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: center;
    border: none;
    padding: 6px 18px;
}

    .site-btn:hover {
        color: var(--white);
        background-color: var(--blue);
    }

    .site-btn img {
        width: 13px;
        margin-right: 7px;
    }

.site-btn-transparent {
    color: var(--menu-link-color);
    background-color: transparent;
    border: none;
    /*font-size: 14px;*/
}

.filter-btn {
    font-family: var(--manrope);
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background: var(--white);
    color: var(--menu-link-color);
}

    .filter-btn:hover {
        background: var(--white);
        border: 1px solid var(--border-color);
        color: var(--menu-link-color);
    }

    .filter-btn:first-child:active {
        background: var(--white);
        border: 1px solid var(--border-color);
        color: var(--menu-link-color);
    }

    .filter-btn.show {
        background: var(--white);
        border: 1px solid var(--border-color);
        color: var(--menu-link-color);
    }

.filter-btn-estates{
    width: 100%;
}

.filter-caret {
    padding-left: 8px;
}

.filter-caret::after {
    margin-left: -0.2em !important;
} 

.dropup:after {
    transform: rotate(180deg)
}

.site-btn-padd {
    padding: 12px 24px;
}

.color-dark {
    color: var(--text-color);
}

.fc .fc-button:disabled {
    opacity: 0.35;
}

.fc-icon {
}

.empty {
    border-color: var(--red-color);
}

.text-danger {
    color: var(--red-color);
}

.message-box {
    position: fixed;
    right: 0;
    bottom: 0;
    /*text-shadow: 0px 0px 7px red;*/
    background: #00000099;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.message-container {
    background-color: white;
    border-radius: 16px;
    padding: 2.3rem 1rem;
    font-family: var(--manrope);
    text-align: center;
    font-size: 1rem;
    width: calc(100%/4);
    left: calc(100%/2.67);
    top: calc(100%/2.67);
    position: absolute;
}

.close-message-box {
    position: absolute;
    right: 15px;
    top: 15px;
}

.message-box-t {
    font-family: var(--manrope);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
}

.login-field-error {
    font-family: var(--manrope);
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    margin-top: 2px;
}

.login-message-box {
    position: absolute;
    bottom: 10%;
    right: 10%;
    padding: 15px;
}

    .login-message-box * {
        font-family: var(--manrope);
        font-size: 14px;
    }

.login-error-box {
    background-color: rgba(224, 49, 55, 0.2);
    color: black;
}

/***********************************Calendar Screen**********************************/
.schedular-container {
    min-height: 100vh;
    overflow-y: auto;
}

.calendar-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    border-radius: 16px;
}

    .calendar-loader .form-container {
        width: 40%;
        margin: 0 auto;
    }

    .calendar-loader p {
        font-family: var(--manrope);
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        color: #111827;
    }

.cal-loader {
    animation: loading 3s linear infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-booking {
    font-size: 10px;
    font-family: var(--manrope);
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    right: 10px;
    top: 10px;
    border-radius: 6px;
    padding: 2px 8px;
    color: black
}

.price-tag {
    font-size: 10px;
    font-family: var(--manrope);
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    left: 10px;
    top: 10px;
    border-radius: 6px;
    padding: 2px 8px;
    color: black
}

.form-container-collapsed {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    padding: 25px 4px 25px 10px;
    position: relative;
}

@keyframes slideaway {
    from {
        display: none;
        transform: translateY(40px);
    }

    to {
        transform: translateY(0px);
    }
}

.form-container-expanded {
    padding: 12px 12px;
}

    .form-container-expanded.selected, .form-container-collapsed .selected .sm-prop-img {
        border: 2.2px solid var(--red-color);
    }

.sm-sup-text {
    font-size: 12px;
    vertical-align: super;
    color: red;
}

.prop-img-container {
    background-color: black;
    border-radius: inherit;
    overflow: hidden;
    text-align: center;
}

.expanded-estates .prop-img-container {
    height: 100%;
}

.expanded-estates .property-img {
    object-fit: contain;
    height: 140px;
    width: 100%;
}

.tooltip-inner .prop-img-container {
    width: 270px;
}

.tooltip-inner .property-img {
    object-fit: contain;
}

.modal-img-container {
    border-radius: 16px;
    overflow: hidden;
    text-align: center;
}

.modal-img-container img {
    height: 140px;
}


.sm-prop-img {
    border-radius: 10px;
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 480px) {
    .expanded-estates .property-img {
        height: 190px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .expanded-estates .property-img {
        height: 190px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .expanded-estates .property-img {
        height: 100px;
    }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .expanded-estates .property-img {
        height: 120px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .expanded-estates .property-img {
        height: 140px;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1750px) {
    .expanded-estates .property-img {
        height: 210px;
    }
}

@media only screen and (min-width: 1750px) and (max-width: 2000px) {
    .expanded-estates .property-img {
        height: 210px;
    }
}

@media only screen and (min-width: 2000px) and (max-width: 2500px) {
    .expanded-estates .property-img {
        height: 290px;
    }
}

@media only screen and (min-width: 2500px) and (max-width: 3000px) {
    .expanded-estates .property-img {
        height: 350px;
    }
}

@media only screen and (min-width: 3000px) and (max-width: 4000px) {
    .expanded-estates .property-img {
        height: 400px;
    }
}

@media only screen and (min-width: 4000px) and (max-width: 5000px) {
    .expanded-estates .property-img {
        height: 500px;
    }
}

@media only screen and (min-width: 5000px) and (max-width: 7000px) {
    .expanded-estates .property-img {
        height: 600px;
    }
}

@media only screen and (min-width: 2000px){
    .sm-prop-img {
        width: 100px;
        height: 100px;
    }

    .tooltip-inner .prop-img-container {
        width: 320px;
        height: 200px;
    }

    .tooltip-inner .property-img {
        height: 200px;
    }
}

@media only screen and (min-width: 4000px) {
    .sm-prop-img {
        width: 150px;
        height: 150px;
    }

    .tooltip-inner .prop-img-container {
        width: 400px;
        height: 250px;
    }

    .tooltip-inner .property-img {
        height: 250px;
    }
}

.property-name {
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 600;
    line-height: 22.4px;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
}

    .property-name span {
        max-width: 170px;
        word-wrap: break-word;
        text-align: left;
    }

.add-slot-link {
    font-family: var(--manrope);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    color: var(--blue);
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.prop-address {
    font-family: var(--manrope);
    font-size: 12px;
    font-weight: 500;
    line-height: 17.6px;
    color: var(--menu-link-color);
    text-align: left;
    /*display: flex;
    align-items: center;*/
}

.small-grey-text {
    font-family: var(--manrope);
    font-size: 12px;
    font-weight: 500;
    line-height: 17.6px;
    color: var(--menu-link-color);
}

.prop-detail-row span {
    color: #212529
}

.prop-detail-row img {
    vertical-align: bottom;
}

.form-container-collapsed, .prop-container {
    overflow-y: auto;
}

.collapsed-properties-scroll {
    overflow-y: scroll;
}

.props-list {
    height: 54px;
}

.search-box {
    height: 38px;
    line-height: 38px;
    padding-left: 32px;
}

.search-icon {
    position: absolute;
    top: 11px;
    left: 10px;
}

.filter-select {
    height: 38px;
    line-height: 18px;
}

.estate-filter-dd{
    width: 250px;
    padding: 15px;
}

.fc * {
    font-family: var(--manrope);
}

.fc-timegrid-event .fc-event-main{
    border: 1px solid #fff;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 20px;
}

.fc-toolbar-chunk {
    display: flex;
    padding: 5px 8px 5px 8px;
    border-radius: 60px;
    background-color: var(--toolbar-bg);
    align-items: center;
}

    .fc-toolbar-chunk:first-child, .fc-toolbar-chunk:nth-child(2) {
        background-color: transparent;
    }

.fc .fc-button {
    font-family: var(--manrope);
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: 0.20000000298023224px;
    color: var(--text-color);
    background-color: transparent;
    border: none;
    border-radius: 50px;
}

    .fc .fc-button:hover, .fc .fc-button:focus {
        background-color: var(--toolbar-button-color);
        box-shadow: none;
        outline: none;
        color: var(--white);
    }

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    background-color: var(--toolbar-button-color);
    box-shadow: none;
    outline: none;
}

.fc-scrollgrid {
    border-radius: 10px;
    background-color: var(--toolbar-bg);
}

    .fc-scrollgrid ~ * {
        font-family: var(--manrope) !important;
        font-size: 14px !important;
    }

.fc-theme-standard .fc-scrollgrid, .fc-theme-standard td, .fc-theme-standard th {
    border-color: var(--ligh-border) !important;
}

.fc-scrollgrid > tbody {
    /*background-color: var(--white);*/
}

.fc-scrollgrid-section th {
    border-top-right-radius: 10px;
    border-right: 0;
}

.fc-scrollgrid-sync-inner {
    padding: 16px;
}

    .fc-scrollgrid-sync-inner a {
        font-family: var(--manrope);
        font-size: 12px;
        font-weight: 500;
        line-height: 19.2px;
        letter-spacing: 0.20000000298023224px;
        text-decoration: none;
        color: var(--light-grey-text);
    }

.fc-scrollgrid-section td {
    border-right: 0;
    border-bottom: 0;
    background-color: white;
    /*border-radius: 0px 0px 10px 10px;*/
}

.fc .fc-scrollgrid {
    border-bottom-width: 1px;
    border-right-width: 1px;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    color: var(--menu-link-color);
    height: 80px;
}

.fc-timegrid-slot-label-cushion, .fc .fc-timegrid-axis-cushion {
    font-family: var(--manrope) !important;
    font-size: 14px !important;
}

.fc .fc-timegrid-divider {
    padding: 0px 0px 1px;
    background-color: var(--ligh-border) !important;
}

.fc-toolbar-title {
    font-family: var(--manrope);
    font-size: 14px !important;
    line-height: 22.4px;
    letter-spacing: 0.20000000298023224px;
    color: var(--black);
}

.fc-prev-button, .fc-next-button {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

    .fc-prev-button:hover, .fc-next-button:hover, .fc-prev-button:focus, .fc-next-button:focus {
        background-color: transparent !important;
        color: var(--text-color) !important;
    }

.fc-v-event {
    /*background-color: var(--light-blue) !important;*/
    border: none !important;
    /*padding: 4px 4px;*/
    padding: 0;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    box-shadow: none !important;
}

.fc .fc-non-business {
    background: hsl(0deg 0% 96.08%);
}

tr > td[data-time="00:00:00"], tr > td[data-time="01:00:00"], tr > td[data-time="02:00:00"], tr > td[data-time="03:00:00"], tr > td[data-time="04:00:00"], tr > td[data-time="05:00:00"],
tr > td[data-time="06:00:00"], tr > td[data-time="07:00:00"], tr > td[data-time="22:00:00"], tr > td[data-time="23:00:00"], .fc-non-business, .fc-timegrid-bg-harness {
    display: none;
}

.event-container {
    padding: 4px 4px;
}

    .event-container * {
        font-size: 11px;
    }

.line-height-11 {
    line-height: 11px;
}

.line-height-8 {
    line-height: 9px;
}

.event-opacity {
    opacity: 0.2;
}

.blue {
    color: var(--blue);
}

.grey {
    color: rgba(0, 0, 0, 0.2);
}

.event-action {
    font-weight: 600;
}

.italic {
    font-style: italic;
}

.rounded-circle {
    /*padding: 0.01rem 0.32rem;*/
    background-color: var(--border-dark);
    color: white;
    border: 2px solid white;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    padding: 0 0.33rem;
    align-items: center;
    text-align: center;
    font-size: 10px;
}

.rounded-circle-small {
    background-color: var(--border-dark);
    color: white;
    border: 2px solid white;
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    padding: 0.1rem 0rem;
    align-items: center;
    text-align: center;
    font-size: 8px;
    border-radius: 50%;
    margin-top: -2px;
}

.pt-0-05 {
    padding-top: 0.05rem;
}

.pt-0-55 {
    padding-top: 0.55rem;
}

.m-top-neg-3 {
    margin-top: -3px;
}

.grey-bg {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
}

.status {
    padding: 2px 8px 1px 8px;
    border-radius: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip {
    --bs-tooltip-max-width: calc(100%/1) !important;
}

    .tooltip.show {
        opacity: 1 !important;
    }

.tooltip-arrow {
    display: none !important;
}

.tooltip-inner {
    padding: 0 !important;
    background-color: transparent !important;
}

.form-steps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.step-bar {
    background-color: var(--menu-link-color);
    height: 2px;
    width: 20px;
    margin-right: 8px;
}

    .step-bar.active {
        background-color: var(--green-color);
    }

.styled-radio {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    position: relative;
    height: 100px;
    display: grid;
    align-items: center;
    justify-content: center;
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    padding: 10px;
}

.radio-label {
    display: unset;
    width: 49%;
}

.invisible-radio {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

    .invisible-radio:checked + label .styled-radio::after {
        content: url('./assets/images/radio-checked.svg');
        display: inline-block;
        box-sizing: border-box;
        padding: 10px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

    .invisible-radio:not(:checked) + label {
        cursor: pointer;
    }

.modal-p-text {
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    color: var(--menu-link-color);
}

.warning {
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    color: var(--warning-txt);
    background-color: var(--warning-bg);
    display: flex;
    align-items: start;
    padding: 10px 16px 10px 16px;
}

.disabled {
    opacity: 0.35;
}

.low-opacity{
    opacity: 0.5;
}
/***********************************abdul saboor***************************************************************/
.login-form-container {
    margin-top: 3rem;
    box-shadow: 0px 14px 44px 0px #00000005;
    background-color: #FFFFFF;
    padding: 30px 30px 20px 30px;
    border-radius: var(--radius);
}

.login-title {
    font-family: var(--manrope);
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: center;
}

.img-container {
    text-align: center;
}
/*
.btn-login {
    background: #5688FB;
    padding: 10px, 31px, 10px, 31px;
    border-radius: 30px;
    color: white;
    width: 159px;
    height: 44px;
    border: none;
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
}*/

.forgot-pass {
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    color: var(--light-grey-text);
}

.create-account {
    color: #CC3232;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    font-family: var(--manrope);
    margin-left: 5px;
}

.rights-text {
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    color: var(--menu-link-color);
    margin-top: 1rem;
}

.login-bottom-links {
    color: var(--text-color);
}

.input-field-icons {
    position: absolute;
    top: calc(100%/2.5);
    right: 20px;
}

.ms-05{
    margin-left: 0.1rem;
}

.room-products {
    font-size: 11px;
    font-weight: 500;
}

.eye-icon {
    top: calc(100%/3.4);
}

.modalStyle {
    border-radius: 16px;
    padding: 32px;
}

.room-info {
    border: 1px solid var(--border-color);
}

.modal-container {
    padding: 32px;
}

.small-img-modal {
    width: 18px;
}

.smallImgIcon-Modal-info {
    font-size: 11px;
}

.selectModal-container .property-name {
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 600;
    line-height: 22.4px;
    color: var(--text-color);
    display: block;
}

.newEvent-btn {
    background: var(--menu-link-color);
    border-radius: 10px;
    color: var(--white);
    font-family: var(--manrope);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: center;
    border: none;
}

.error-message {
    color: var(--red-color);
    font-family: var(--manrope);
    font-size: 11px;
}

.day-lbl {
    width: 85px;
}

.slots-img{
    vertical-align: top !important;
}

.slots-container {
    max-height: 110px;
    overflow-y: scroll;
    padding-right: 10px;
}

.text-highlighted{
    color: var(--blue);
    font-weight: bold;
}

.border-top-light {
    border-top: 1px solid var(--menu-active-color);
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 14px;
    text-align: left;
    border-radius: 12px;
}

th, td {
    padding: 7px;
    border: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

.btn-delete {
    background-color: var(--red-color);
}